/* website: 3208-grandtoyota
 * created at 2020-10-01 8:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/toyota.scss";
@import "../utils/icons.scss";
.widget-sr{
  .calculator-tradeIn{
    .sr-link{
      display: none !important;
    }
  }
}